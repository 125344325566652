div.delete-network-dialog div.modal-content {
    margin-top: 81%;
    width: 75%;
    height:190px;
    margin-left: 10%;
   }

.delete-network-text{
    font-size: 14px;
}
.delete-header{
    height:50px !important;
}
.delete-body{
    height:80px !important;
}
.delete-footer{
    height:50px !important;
}