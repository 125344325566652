.createOrganizationHeader{
    width: 200px;
}
.createOrganizationHeaderTxt{
    margin-left: 1%;
    font-size: 22px;
}
.selectFieldFont{
    font-size: 1em !important;
}
.errorFont{
    font-size: 11px !important;
}

