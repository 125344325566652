div.edit-device-dialog div.modal-content {
   width: 146% !important;
   margin-top: 31% !important;
   }

.deviceHeader{
   width: 300px;
}
.deviceHeaderTxt{
   margin-left: 3%;
}