@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html{
  /* //transform: scale(.5);    */
   zoom: 100%
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

code {
  font-family: 'Rubik', sans-serif;
    
}


body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Rubik', sans-serif;
   
}


body{
  font-family: 'Rubik', sans-serif !important;
}
.imagbg{
  background: linear-gradient(90deg, rgb(44 55 137) 0%, rgb(124 45 231 / 91%) 100%);
  width: 100%;
  height: 600px;
  background-size:contain;
  background-repeat: no-repeat;
  border-radius: 0 0 0 60px;
 
}
.header-text{
  color: #fff;
  margin-left: 30px;
  font-size: 36px;
}
.welcom-text{
  color: #fff;
  font-size: 2.1em;
  margin-left: 30px;
  margin-top: 8px;

}
.bg-box{
  background: #fff;
  min-height: 500px;
  position: relative;
  margin: 30px;
  border-radius: 10px;
  border: 1px solid #ced4da;
}
.bg-box-trasparant{
  min-height: 500px;
  position: relative;
  margin: 30px;
  
  /* border: 1px solid #ced4da; */

}
table>thead>tr>th{
  font-size: 1em;
  text-wrap: nowrap;
}
table.dataTable thead>tr>th.sorting:before, table.dataTable thead>tr>th.sorting_asc:before, table.dataTable thead>tr>th.sorting_desc:before, table.dataTable thead>tr>th.sorting_asc_disabled:before, table.dataTable thead>tr>th.sorting_desc_disabled:before, table.dataTable thead>tr>td.sorting:before, table.dataTable thead>tr>td.sorting_asc:before, table.dataTable thead>tr>td.sorting_desc:before, table.dataTable thead>tr>td.sorting_asc_disabled:before, table.dataTable thead>tr>td.sorting_desc_disabled:before
{
  color: #2c3789;
}
table.dataTable thead>tr>th.sorting:after, table.dataTable thead>tr>th.sorting_asc:after, table.dataTable thead>tr>th.sorting_desc:after, table.dataTable thead>tr>th.sorting_asc_disabled:after, table.dataTable thead>tr>th.sorting_desc_disabled:after, table.dataTable thead>tr>td.sorting:after, table.dataTable thead>tr>td.sorting_asc:after, table.dataTable thead>tr>td.sorting_desc:after, table.dataTable thead>tr>td.sorting_asc_disabled:after, table.dataTable thead>tr>td.sorting_desc_disabled:after
{ color: #2c3789;

}
.btn-view{
  background: #2c3789;
  
  border-radius: 3px;
  width: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;


}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2c3789;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2c3789;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}


div.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f1f1f1;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #ddd;
}


div.dataTables_wrapper .dataTables_filter input[type="search"] {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f1f1f1;
}


div.dataTables_wrapper .dataTables_length select {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f1f1f1;
}


div.dataTables_wrapper .dataTables_info {
  margin-top: 10px;
  font-size: 12px;
  color: #888;
}

div.dataTables_wrapper .dataTables_paginate, 
div.dataTables_wrapper .dataTables_info {
float: right;
}


div.dataTables_wrapper .dataTables_info {
margin-right: 15px;
}


div.dataTables_wrapper .dataTables_length {
text-align: right;  margin-top: 10px;
font-size: 12px;
}
div.dataTables_wrapper div.dataTables_filter label{
font-size: 12px;
font-weight: bold;
}
div.dataTables_wrapper .dataTables_filter input[type="search"]{
margin-left: 10px !important;
}


div.dataTables_wrapper .dataTables_filter input[type="search"] {
margin: 10px 0;
padding: 5px;
border: 1px solid #ced4da;
border-radius: 5px;
background-color: #f1f1f1;
}

/* Style the "Show x entries" dropdown */
div.dataTables_wrapper .dataTables_length select {
margin: 10px 0;
padding: 5px;
border: 1px solid #ced4da;
border-radius: 5px;
background-color: #f1f1f1;
}
.dataTables_info{
text-align: right;

}

div.dataTables_wrapper .dataTables_paginate {
  text-align: right !important;
  margin-top: 15px !important;
  font-size: 12px;
  color: #2c3789!important;
  font-weight: bold;
}


div.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0 2px;
}
.bottom{ flex-direction: row; display: flex; justify-content: space-between;

}
.pagination{ flex-direction: row; display: flex; justify-content: flex-start;
  width: "100%";

}
.paginationOuterDiv{ flex-direction: row; display: flex; justify-content: flex-start;
  width: "100%";

}
table.dataTable td, table.dataTable th{
  font-size: 11px !important;
}
li.list-group-item {
  border: none;
  cursor: pointer;
}
li.list-group-item>img {
  margin-right: 16px;
  width: 25px;
}
.titelcolostyle{
  color: #34368a;
  font-size: 22px;
  text-align: left;
  margin-top: 20px;
}
.searchbox{
  text-align: right;
}

.marg-right-3{
  margin-right: 20px;
}

/* table */
.table{
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table>tbody>tr{
 
  background: #F9F9F9;
  margin-bottom: 10px;
 
}
table>thead>tr>th{
  font-size: 1em;
  font-weight: 700;
  text-align: left;
}
table>tbody>tr>td{
  font-size: 1em;
  text-align: left;
  vertical-align: middle !important;
}


/* buttons */
.btn-secondary {
  background-color: #2c3789 !important;
  color: white; /* Set text color to contrast with the background color */
  border-color: #2c3789 !important;

}
a{
  color: #000!important;
  text-decoration: none!important;
}
.icon-left-m{
  margin-left: 6px;
  height: 12px;
  width: 10px;
}
input.form-control.mb-3.cutomesearchbox {
  width: 200px;
  float: right;
  font-size: 1em;
  margin-right: 10px;
 
}
.list-action{
  display: flex;
  

}
.form-check-input:checked {
  background-color: #2c3789 !important;
  border-color: #2c3789 !important;
}
.form-switch .form-check-input {
  width: 44px !important;
 cursor: pointer;
  height: 21px !important;
  margin-right: 20px !important;
}
.header-fuctions{
  display: flex;
  flex-direction: row;
  justify-content: end;
  
  
}
.header-fuctions> div{
  text-align: right;
  
}
.addbtn{
  cursor: pointer;
}
.addbtn{
  background: rgb(54,50,218);
background: linear-gradient(90deg, rgb(44 55 137) 0%, rgb(124 45 231 / 91%) 100%);
border: none;
padding: 8px;
border-radius: 25px;
width: 200px;
color: #fff;
font-size: 1.1em;
}
.App{
  text-align: left!important;
}
.table-scrollable {
  width: 100%;
  overflow-x: auto;
}
label.form-check-label.custom-lable {
  position: relative;
  left: -10px;
  top: 4px;
}
.notificastions-box{
  /* border: 1px solid #000 !important; */
  background: #FFFFFF;
  border-radius: 15px;
  height: 382px;
  
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  
  
}
.header-text1>h4{
  font-size: 18px;
  font-weight: bold;
  color: #858997;
  padding: 20px 15px 15px 20px;
}
.list-notifications{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #B5B8BF;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 0 8px 0px;
}
.thumimage{
  /* background: url(./images/notificastionthum.png); */
    height: 37px;
    width: 37px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    background: linear-gradient(90deg, rgb(44 55 137) 0%, rgb(124 45 231 / 91%) 100%);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    padding: 17px;
}
.list-notifi>li{
  list-style: none;
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 8px;
  
}
.list-notifi{
  margin: 14px;
  padding: 0px;
  overflow-y: scroll;
  height: 270px;
}
.notification-text >p{
  font-size: 1em;
  font-weight: bold;
  color: #464E5F;
  margin: 0px;
  padding: 0px;
  width: 276px;
}
.notification-text >small{
  font-size: 1em;
  font-weight: 600;
  color: #B5B8BF;
  margin: 0px;
  padding: 0px;
}
.date-notifications{
  font-size: 1em;
  font-weight: bold;
  color: #464E5F;
  margin: 0px;
  padding: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.box-1{
  background: #FFFFFF;
  border-radius: 15px;
  height: 180px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  
}
.boxlist{
  padding: 20px;
  margin: 0px;
}
.boxlist>li{
  padding: 0px;
  margin: 0px;
  list-style: none;

}
.box-title{
  font-size: 18px;
  padding-top:15px;
  color: #858997;
  font-weight: bold;
  margin-bottom: 15px;

}
.addbtn-smaall{
  background: rgb(54,50,218);
  background: linear-gradient(90deg, rgb(44 55 137) 0%, rgb(124 45 231 / 91%) 100%);
    border: none;
    padding: 10px;
    border-radius: 25px;
    /* width: 200px; */
    color: #fff;
    font-size: 8px;
}
.icon-deskboad{
  width: 100px;
  height: 100px;
  background: url(./images/org.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 20px;
  position: relative;
  top: -24px;
}
.icon-Devices{
  width: 80px;
  height: 80px;
  background: url(./images/devies.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 20px;
  position: relative;
  top: -24px;
}
.icon-Networks{
  width: 80px;
  height: 80px;
  background: url(./images/networkiconD.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 20px;
  position: relative;
  top: -24px;
}
.pending-Devices{
  width: 80px;
  height: 80px;
  background: url(./images/pndon.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 20px;
  position: relative;
  top: -24px;
}
.icons-number{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.number-bold{
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
}
.chart-1{
  background: url(./images/Group\ 52.svg);
  height: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 447px;
    background-position: -38px 2px;
   
    border-radius: 15px;
    
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  
}
.chart-2{
  background: url(./images/Group\ 66.svg);
  height: 610px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 746px;
    background-position: -38px 2px;
    border-radius: 15px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  
}
.require {
  color: #ff0000e0;
}
.label{
color: #656669;
}

.nav-tabs .nav-item .active {

    color: #ffffff !important;
    background-color: #2c3789 !important;
    font-size: 1em;
}
.nav-link{
  font-size: 1em;
  
}
.form-control:disabled{
  background-color:#4e92e43d !important
}
li .active{
 
}


.scrollable-table-test{
  height: 500px;
  position: relative;
  overflow: auto;
  margin-bottom: 10px;
}
.sticky-top{
  background: #fff;
}


@media (min-width: 576px){
  #exampleModalOrganization>.modal-dialog {
    max-width: 700px !important;
    margin: 1.75rem auto;
}
}


.form-group>label{
  font-size: 1em;
}
input.form-control,select.form-control{
  font-size: 1em;
}
.form-group{
  padding: 8px;
}
.modal-header{
  border-bottom: #2c3789 3px solid !important;
}
.modal-footer{
  margin-top: 8px;
  padding: 0px!important;
  border-top: none!important;
}
.form-select {
  font-size: 1em !important;
}

.inactive-deskboard,.inactive-org,.inactive-net,.inactive-div,.inactive-con,.inactive-out{
  
  
    
    /* background: url(./images/Organizationsicon.svg); */
    height: 35px;
    width: 34px;
    background-size: contain;
    margin-right: 5px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    color: #000;
    border-radius: 0 15px 15px 0px;
    align-items: center;

}
.active-deskboard, .active-org,.active-net,.active-div,.active-con,.active-out{
  display: none;
  
}
.active>.active-deskboard, .active>.active-org, .active>.active-net,.active>.active-div,.active>.active-con,.active>.active-out{
  /* background: url(./images/Organizationsicon.svg); */
  height: 35px;
  width: 34px;
  background-size: contain;
  margin-right: 5px;
  background-color: #2c3789;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 0 15px 15px 0px;
  align-items: center;
}
.active>.inactive-deskboard,.active>.inactive-org,.active>.inactive-net,.active>.inactive-div,.active>.inactive-con,.active>.inactive-out{
  display: none;
}

li>a {
  display: flex;
  flex-direction: row;
  vertical-align: bottom;
  align-items: center;
  font-size: 1.1em;
}





.contanct-images{
  width: auto;
    height: 410px;
}
.pageicon{
  color: #7977fc;
    font-size: 24px;
    margin-right: 8px;
}

select{
  font-size: 1em!important;
  width: 100%;
  height: 38px;
  border: 1px solid #ced4da;
}
.list-action>.btn-sm{
  font-size: .675rem;

}
.logout-span{
  display: flex;
    flex-direction: row;
    vertical-align: bottom;
    align-items: center;
    color: #000!important;
    text-decoration: none!important;
}
.css-16xfy0z-control {

  background-color: #d5e5f9 !important;
  
  height: 35px !important;
  min-height: 35px !important;
  padding: 0px !important;
  margin: 0px !important;
  
  }

  .css-16xfy0z-control>input{

    color:#212529 !important;
    
    font-size:1em !important;
    
  }
  .confirmModal{
    color: #34368a;
  }
  
  .refreshBtn{
    background: rgb(54,50,218);
    background: linear-gradient(90deg, rgb(44 55 137) 0%, rgb(124 45 231 / 91%) 100%);
    border: none;
    padding: 9px 11px;
    border-radius: 30px;
    position: relative;
    /* width: 30px; */
    /* height: 30px; */
    color: #fff;
    font-size: 1.1em;
    top:42px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
         -webkit-appearance: none;
          margin: 0;
  }

  input[type=number] {
      -moz-appearance: textfield;
  }
  .actionDropdown{
    min-width: 5rem !important;
  }

h3.table-text {
  font-size: 14px;
  color: #858997;
  padding-left: 10px;
}
.data-table{
font-size: 14px;
color: #2c3789;
font-weight: bold;
text-align: right;
padding-right: 10px;
}
.deskboad-table>:not(caption)>*>*{
padding: 0px !important;
}
.deskboad-table{
margin-bottom: 0px !important;  
}
/* #preloader{overflow:hidden;background:#fff;left:0;right:0;top:0;bottom:0;position:fixed;z-index:9999}  */
.spinner{position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;text-align:center}
.loader{height:20px;width:100%;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto}
.moving-dot{animation-name:loader;
animation-timing-function:cubic-bezier(0, 0.61, 0.71, 0.24);
animation-duration:5s;
animation-iteration-count:1;
height:20px;width:20px;border-radius:100%;
background-color:black;position:absolute;border:2px solid white}
.moving-dot:first-child{background-color:#212269;animation-delay:0.5s}
.moving-dot:nth-child(2){background-color:#8c6daf;animation-delay:0.4s}
/* .moving-dot:nth-child(3){background-color:#ef5d74;animation-delay:0.3s}
.moving-dot:nth-child(4){background-color:#f9a74b;animation-delay:0.2s}
.moving-dot:nth-child(5){background-color:#60beeb;animation-delay:0.1s}
.moving-dot:nth-child(6){background-color:#fbef5a;animation-delay:0s} */
@keyframes loader{15%{transform:translateX(0)}
100%{transform:translateX(950px)}}
.line-data{
height: 5px;
width: 100%;
background-color: #212269;
position: relative;
top: 8px;
}
.deskboad-bg-box{
  background: #fff;
  min-height: 180;
  position: relative;
  /* margin: 30px; */
  border-radius: 10px;
  border: 1px solid #ced4da;
}
.deskboad-notificastions-box{
  /* border: 1px solid #000 !important; */
  background: #FFFFFF;
  border-radius: 15px;
  height: 435px;
  
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  
  
}

.modal-dialog.modal-dialog-centered{
  margin-top: 0px;
  margin-bottom: 0px;
}
.closealiment{
  text-align: end;
  padding-right: 10px;
}