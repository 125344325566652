div.edit-organization-dialog div.modal-content {
    width: 148%;
    margin-top: 31%;
   }
   
.editOrganizationHeader{
    width:200px;
}

.editOrganizationHeaderText{
    margin-left: 3%;
}

.selectFieldFont{
    font-size: 12px !important;
}
