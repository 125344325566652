.audit-filter-popup-Network{
    margin-top: 1%;
    height: 330px;
}
.audit-filter-popup-Device{
    margin-top: 1%;
    min-height: 480px;
}
.audit-close{
    font-size: 12px;
    float: left;
    margin-left: 6%;
}
.audit-header{
    font-weight: bold;
    margin-left:30% !important;
    text-align: left;
    display: block;
}
.clear-filter{
    float: right;
    margin-right: 5%;
    font-weight: bold;
    color: #245abc;
}
.audit-form{
    margin-top: 3% !important;
}
.entity-type{
    font-weight: bold;
    margin-left: 2%;
}
.audit-filter-dialog{
    margin-top: 10% !important;
    margin-left: 34% !important;
    max-width: 40% !important;
}
.entity-selection{
    margin-top: 1% !important;
    margin-left: 1% !important;
}
.audit-filter-search{
    width: 30% !important;
    margin-top: 5% !important;
    margin-left: 65% !important;
}
.startData{
    width: 100%;
}
.endDate{
    width: 100%;
}
.audit-filter-popup-Network{
    margin-top: 1%;
    height: 450px;
}
.audit-filter-popup-Device{
    margin-top: 1%;
    min-height: 480px;
}